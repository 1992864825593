import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import _ from "lodash";
import { PagedTable } from "components/dataDisplay/PagedTable";

type RowObj = {
  strategy: string;
  investment: string;
  transaction: string;
  event: string;
  entryDate: Date;
  numberOfSecurities: number;
  typeOfSecurity: string;
  nav: number;
  cashFlow: number;
  actualValue: number;
};

export default function TransactionDetails(props: {
  showHistoricalData: boolean;
}) {
  const { showHistoricalData } = props;
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const translations = useSelector((state: RootState) =>
    state.data.translationMappingSettings.transactions.filter(
      (x) => x.type === "Share"
    )
  );
  const securityClasses = useSelector((state: RootState) =>
    state.data.securityTypeMappingSettings.securityTypes.filter(
      (x) => selectedStrategyIds.some(strategyId => x.strategyIds.includes(strategyId))
    )
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  const currencyFormatter4Decimals = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 4
      }),
    []
  );

  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("nl-BE", {}), []);
  const numberFormatter = useMemo(() => new Intl.NumberFormat("nl-BE", {}), []);

  const columnHelper = createColumnHelper<RowObj>();
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  const translateEvent = (event: string) => {
    return translations.find((x) => x.original === event)?.translated ?? event;
  };

  const translateSecurity = useCallback((security: string) => {
    return securityClasses.flatMap(x => x.mappings).find(x => x.original === security)?.translated ?? security;
  }, [securityClasses]);

  const transactionRows = useMemo<RowObj[]>(() => {
    const rows: RowObj[] = [];

    selectedStrategyIds.forEach((strategyId) => {
      const strategyName = strategyCalculator.getStrategyNameById(strategyId);
      const investment =
        strategyCalculator.getCombinedNameByStrategyId(strategyId);

      stakeholders.forEach((st) => {
        console.log(`Actual value calculation Stakeholder ${st.ownerId}:`);

        st.shares.forEach((share) => {
          if (strategyId !== share.strategyId) {
            return;
          }

          share.shareTransactions
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() <
                calculationDate.getTime()
            )
            ?.forEach((transaction) => {
              const currentShareValuePrice =
                strategyCalculator.getShareValuePrice(
                  share.strategyId,
                  calculationDate,
                  translateSecurity(transaction.holder.securityClass)
                );

              rows.push({
                strategy: strategyName,
                investment: investment,
                transaction: transaction.type,
                event:
                  transaction.description?.length > 0
                    ? transaction.description
                    : "-",
                entryDate: new Date(transaction.transactionDate),
                numberOfSecurities: transaction.holder.numberOfSecurities,
                typeOfSecurity:
                  transaction.holder.securityClass?.length > 0
                    ? transaction.holder.securityClass
                    : "-",
                nav: currentShareValuePrice,
                cashFlow: strategyCalculator.calculateCashflow(
                  transaction
                ) * -1,
                actualValue:
                  transaction.holder.numberOfSecurities *
                  currentShareValuePrice,
              });
            });
        });
      });
    });

    return rows;
  }, [calculationDate, selectedStrategyIds, stakeholders, strategyCalculator, translateSecurity]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const columns = [
    columnHelper.accessor("strategy", {
      id: "strategy",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Strategy
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="400">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("investment", {
      id: "investment",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Investment
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction", {
      id: "transaction",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Transaction
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null ? translateEvent(info.getValue()) : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("event", {
      id: "event",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Event
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null ? info.getValue() : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("entryDate", {
      id: "entryDate",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Entry Date
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {dateFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("cashFlow", {
      id: "cashFlow",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Cashflow
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("numberOfSecurities", {
      id: "numberOfSecurities",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Number of securities
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {numberFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("typeOfSecurity", {
      id: "typeOfSecurity",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Type of security
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {translateSecurity(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("nav", {
      id: "nav",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          NAV
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter4Decimals.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("actualValue", {
      id: "actualValue",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Actual Value
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
  ];

  return (
    <PagedTable
      title="Transaction Details"
      columns={columns}
      data={transactionRows}
    />
  );
}
