import { Box, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useEffect, useMemo, useState } from 'react';
import LineChart from 'components/charts/LineChart';
import StrategyCalculator from 'utils/StrategyCalculator';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { LineChartOptionsConsumption } from 'variables/charts';

export default function HistoricalValue(props: { [x: string]: any }) {
  const { ...rest } = props;

  const { loading } = useSelector((state: RootState) => state.data);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat('nl-BE', {
        style: 'currency',
        currency: 'EUR',
      }),
    []
  );

  const [lineChartData, setLineChartData] = useState<any>();

  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const currentDate = useMemo(() => new Date(), []);

  const oldestTransactionDate = useMemo<any>(() => {
    return strategyCalculator.getOldestTransactionDate(
      stakeholders,
      selectedStrategyIds,
      currentDate
    );
  }, [currentDate, selectedStrategyIds, stakeholders, strategyCalculator]);

  const oldestYear = useMemo(() => {
    return new Date(oldestTransactionDate).getFullYear();
  }, [oldestTransactionDate]);

  const mostRecentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  const dateRanges = useMemo(() => {
    const ranges = [];
    for (let year = oldestYear; year <= mostRecentYear; year++) {
      ranges.push({
        endDate: new Date(year, 0, 1),
        label: year.toString(),
      });
    }
    ranges.push({ endDate: new Date(), label: 'Today' });
    return ranges;
  }, [oldestYear, mostRecentYear]);

  function getLastFourQuarters(): string[] {
    const today = new Date();
    const quarters = [];
  
    const getQuarter = (month: number) => Math.floor(month / 3) + 1;
  
    for (let i = 0; i < 4; i++) {
      const month = today.getMonth() - i * 3;
      const year = today.getFullYear() + Math.floor(month / 12);
      const quarter = getQuarter((month + 12) % 12);
      quarters.push(`Q${quarter} ${year}`);
    }
  
    return quarters.reverse(); // We reverse to display the oldest first
  }

  function getEndDateForQuarter(input: string): Date {
    const quarter = input.split(" ")[0];
    const year = input.split(" ")[1];
  
    // this returns the day after the last day of the quarter (last day = 31/03/2024 23:59:59 => 01/04/2024 00:00:00)
    switch (quarter) {
      case "Q1":
        return new Date(+year, 3, 1);
      case "Q2":
        return new Date(+year, 6, 1);
      case "Q3":
        return new Date(+year, 9, 1);
      case "Q4":
        return new Date(+year + 1, 0, 1);
      default:
        return new Date();
    }
  }

  useEffect(() => {
    if (stakeholders && strategyMappingSettings) {
      const dates = getLastFourQuarters().map(range => getEndDateForQuarter(range));
      const actualValues = dates.map(endDate => strategyCalculator.calculateActualValue(stakeholders, selectedStrategyIds, endDate));

      const data: (string | number | object)[][] = [];
      data.push([
        'Period',
        'Actual Value',
        { role: 'tooltip' },
      ]);

      getLastFourQuarters().forEach((range, index) => {
        const value = actualValues[index];

        data.push([
          range,
          value,
          `Actual Value: ${currencyFormatter.format(value)}`,
        ]);
      });

      setLineChartData(data);
    }
  }, [stakeholders, selectedStrategyIds, dateRanges, strategyMappingSettings, strategyCalculator, currencyFormatter]);

  return (
    <Card
      p="20px"
      alignItems="center"
      flexDirection="column"
      w="100%"
      h="100%"
      {...rest}
    >
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Historical Value
        </Text>
      </Flex>
      {loading ? (
        <Spinner />
      ) : (
        <Flex align="center" w="100%">
          <LineChart
            chartData={lineChartData}
            chartOptions={LineChartOptionsConsumption}
          />
        </Flex>
      )}
    </Card>
  );
}
