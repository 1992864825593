import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import { PagedTable } from "components/dataDisplay/PagedTable";

type RowObj = {
  strategy: string;
  combinedStrategy: string;
  initialInvestmentDate: Date;
  initialValue: number;
  initialValueSHL: number;
  paid: number;
  toPay: number;
  distributions: number;
  actualValue: number;
  totalValue: number;
  mm: number;
  numberOfSecurities: number;
};

export default function PortfolioDetails() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  const numberFormatter = useMemo(() => new Intl.NumberFormat("nl-BE", {}), []);
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("nl-BE", {}), []);

  const columnHelper = createColumnHelper<RowObj>();
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const currentDate = useMemo(() => new Date(), []);

  const [groupedRows, setGroupedRows] = useState<RowObj[]>([]);
  const ungroupedRows = useMemo<RowObj[]>(() => {
    return selectedStrategyIds
      .filter((strategyId) =>
        strategyCalculator.doesStrategyApply(strategyId, stakeholders)
      )
      .map((strategyId) => {
        const strategyName = strategyCalculator.getStrategyNameById(strategyId);
        const combinedStrategyName =
          strategyCalculator.getCombinedNameByStrategyId(strategyId);
        const initialInvestmentDate =
          strategyCalculator.getOldestTransactionDate(
            stakeholders,
            [strategyId],
            currentDate
          );
        const initialValue = strategyCalculator.calculateInitialValue(
          stakeholders,
          [strategyId],
          new Date()
        );
        const initialValueSHL = strategyCalculator.calculateInitialLoanValue(
          stakeholders,
          [strategyId],
          new Date()
        );
        const distributions = strategyCalculator.calculateGrossDistributions(
          stakeholders,
          [strategyId],
          currentDate
        );
        const actualValue = strategyCalculator.calculateActualValue(
          stakeholders,
          [strategyId],
          new Date()
        );
        const unpaidValue = strategyCalculator.calculateUnpaidValue(
          stakeholders,
          [strategyId],
          currentDate
        );
        const paidValue = strategyCalculator.calculatePaidValue(
          stakeholders,
          [strategyId],
          currentDate
        );
        const numberOfSecurities = strategyCalculator.calculateNumberOfSecurities(
          stakeholders,
          [strategyId],
          currentDate
        );

        const totalValue = actualValue + distributions;

        return {
          strategy: strategyName,
          combinedStrategy: combinedStrategyName,
          initialInvestmentDate: initialInvestmentDate,
          initialValue: initialValue,
          initialValueSHL: initialValueSHL,
          numberOfSecurities: numberOfSecurities,
          paid: paidValue,
          toPay: unpaidValue,
          distributions: distributions,
          actualValue: actualValue,
          totalValue: totalValue,
          mm: +(totalValue / (initialValue as number)).toFixed(2),
        };
      });
  }, [currentDate, selectedStrategyIds, stakeholders, strategyCalculator]);

  useEffect(() => {
    const rows: { [key: string]: RowObj } = {};
    const investmentsToHide = ['Commitment'];

    ungroupedRows.forEach((data) => {
      if (!rows[data.combinedStrategy]) {
        rows[data.combinedStrategy] = {
          strategy: data.strategy,
          combinedStrategy: data.combinedStrategy,
          initialInvestmentDate: data.initialInvestmentDate,
          initialValue: 0,
          initialValueSHL: 0,
          paid: 0,
          toPay: 0,
          distributions: 0,
          actualValue: 0,
          totalValue: 0,
          mm: 0,
          numberOfSecurities: 0
        };
      }

      // these values we can just add.
      rows[data.combinedStrategy].initialValue += data.initialValue;
      rows[data.combinedStrategy].initialValueSHL += data.initialValueSHL;
      rows[data.combinedStrategy].distributions += data.distributions;
      rows[data.combinedStrategy].actualValue += data.actualValue;
      rows[data.combinedStrategy].paid += data.paid;
      rows[data.combinedStrategy].toPay += data.toPay;
      rows[data.combinedStrategy].totalValue += data.totalValue;
      rows[data.combinedStrategy].numberOfSecurities += data.numberOfSecurities;

      // the values we need to recalculate per row.
      rows[data.combinedStrategy].initialInvestmentDate =
        data.initialInvestmentDate <
        rows[data.combinedStrategy].initialInvestmentDate
          ? data.initialInvestmentDate
          : rows[data.combinedStrategy].initialInvestmentDate;
      rows[data.combinedStrategy].mm = +(
        rows[data.combinedStrategy].totalValue /
        (rows[data.combinedStrategy].initialValue as number)
      ).toFixed(2);
    });
    const sortedRows = Object.values(rows).sort(
      (a, b) => b.initialInvestmentDate.getTime() - a.initialInvestmentDate.getTime()
    )
    .filter(x => !investmentsToHide.includes(x.combinedStrategy)) // filter the rows we do not want to see.

    setGroupedRows(sortedRows);
  }, [ungroupedRows]);

  const columns = [
    columnHelper.accessor("strategy", {
      id: "strategy",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Strategy
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="400">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("combinedStrategy", {
      id: "combinedStrategy",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Investment
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("initialInvestmentDate", {
      id: "initialInvestmentDate",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Initial Investment Date
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {dateFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("initialValue", {
      id: "initialValue",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Total Initial Value
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("initialValueSHL", {
      id: "initialValueSHL",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Initial Value SHL
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("paid", {
      id: "paid",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Shares Paid Up
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("toPay", {
      id: "toPay",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Shares To Pay Up
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("distributions", {
      id: "distributions",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Distributions
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("numberOfSecurities", {
      id: "numberOfSecurities",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Number of shares
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {numberFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("actualValue", {
      id: "actualValue",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Actual Value
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="400">
            {currencyFormatter.format(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("totalValue", {
      id: "totalValue",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          Total Value
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("mm", {
      id: "mm",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.600">
          MM
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}x
        </Text>
      ),
    }),
  ];

  return (
    <PagedTable
      title="Portfolio Details"
      columns={columns}
      data={groupedRows}
    />
  );
}
