// Chakra imports
import { Flex, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
// Custom components
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Spinner } from "@chakra-ui/react";
import _ from "lodash";
import StrategyCalculator from "utils/StrategyCalculator";
import Chart from "react-google-charts";
import { globalStyles } from "theme/styles";

export default function ReturnValue(props: {
  showHistoricalData: boolean;
  [x: string]: any;
}) {
  const { showHistoricalData, ...rest } = props;
  const { loading } = useSelector((state: RootState) => state.data);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2, // Ensure two decimal places
      }),
    []
  );
  const [barChartData, setBarChartData] = useState<any>([
    ["Category", "Value", { role: "tooltip" }, { role: "style" }],
  ]);
  
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  // shows one color for positive numbers, another color for negative numbers.
  const colorPicker = (input: number): string => {
    if (input < 0) {
      return globalStyles.customColors.bermudaGray;
    } else {
      return globalStyles.customColors.spannishGreen;
    }
  }

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  useEffect(() => {
    if (!loading && stakeholders && strategyMappingSettings) {
      if (stakeholders?.length) {
        const actualValue = strategyCalculator.calculateActualValue(
          stakeholders,
          selectedStrategyIds,
          calculationDate
        );
  
        const initialValue =
          strategyCalculator.calculateInitialValue(
            stakeholders,
            selectedStrategyIds,
            calculationDate
          ) * -1;
  
        const distributions = strategyCalculator.calculateGrossDistributions(
          stakeholders,
          selectedStrategyIds,
          calculationDate
        );
  
        const totalReturn = initialValue + actualValue + distributions;
  
        const data: (string | number | object)[][] = [
          ["Category", "Value", { role: "tooltip" }, { role: "style" }],
        ];
        data.push([
          "Initial Value",
          initialValue,
          `Initial Value: ${currencyFormatter.format(initialValue)}`,
          colorPicker(initialValue),
        ]);
        data.push([
          "Distributions",
          distributions,
          `Distributions: ${currencyFormatter.format(distributions)}`,
          colorPicker(distributions),
        ]);
        data.push([
          "Actual Value",
          actualValue,
          `Actual Value: ${currencyFormatter.format(actualValue)}`,
          colorPicker(actualValue),
        ]);
        data.push([
          "Total Return",
          totalReturn,
          `Total Return: ${currencyFormatter.format(totalReturn)}`,
          globalStyles.customColors.flamePea,
        ]);
  
        setBarChartData(data);
      }
    }
  }, [
    loading,
    stakeholders,
    strategyMappingSettings,
    selectedStrategyIds,
    strategyCalculator,
    currencyFormatter,
    calculationDate,
  ]);

  const fontSize = useBreakpointValue({ sm:9, md: "14px", xl: "14px" });

  const barChartOptions = {
    legend: { position: "none" },
    tooltip: {
      textStyle: {
        fontSize: 12,
        fontFamily: "Source Sans Pro",
      },
      theme: "dark",
    },
    fontName: "Source Sans Pro",
    colors: globalStyles.customColors.all,
    vAxis: {
      textStyle: {
        color: globalStyles.customColors.osloGray,
        fontSize: {fontSize},
        italic: false,
        fontName: "Source Sans Pro",
      },

      format: "€#,##0.00",
      gridlines: {
        color: "rgba(163, 174, 208, 0.3)",
      },
    },
    hAxis: {
      textStyle: {
        color: globalStyles.customColors.osloGray,
        fontSize: {fontSize},
        italic: false,
        fontName: "Source Sans Pro",
      },
    },
    chartArea: {
      left: 85, 
      top: 40,
      right: 20, 
      bottom: 50,
    },
  };

  return (
    <Card
  
      alignItems="center"
      flexDirection="column"
      w="100%"
      h="100%"
      {...rest}
    >
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Return Value
        </Text>
      </Flex>
      {loading ? (
        <Spinner />
      ) : (
        <Flex align="center" w="100%">
          {barChartData.length > 1 ? (
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={barChartData}
              options={barChartOptions}
              chartPackages={["corechart"]}
              chartLanguage="ja"
            />
          ) : (
            <Flex align="center" justify="center" w="100%" h="100%">
            <Spinner/>
          </Flex>
          )}
        </Flex>
      )}
    </Card>
  );
  
}
