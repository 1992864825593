import React from "react";
import Chart from "react-google-charts";
import { GoogleChartWrapper } from "react-google-charts";
import { globalStyles } from "theme/styles";

type ChartProps = {
  chartData: any;
  onClick: (strategyName: string) => void;
};

const PieChart: React.FC<ChartProps> = ({ chartData, onClick }) => {
  const handleSelect = (chartWrapper: GoogleChartWrapper) => {
    const chart = chartWrapper?.getChart();

    if (chart) {
      const selection = chart.getSelection();

      if (selection.length) {
        const { row } = selection[0];
        const dataTable = chartWrapper.getDataTable();
        if (dataTable) {
          const strategyName = dataTable.getValue(row, 0).toString();
          onClick(strategyName);
        }
      }
    }
  };

  const chartOptions: any = {
    chartArea: {
      left: "10%",
      top: "10%",
      bottom: "5%",
      right:"5%"
    },
    tooltip: { isHtml: true },
    fontName: "Source Sans Pro",
    colors: globalStyles.customColors.all,
  };

  return (

    <div id="chart_div" style={{ width: "100%", height: "100%" }}>
      <Chart
        chartType="PieChart"
        data={chartData}
        options={chartOptions}
        width={"100%"}
        height={400}
        chartEvents={[
          {
            eventName: "select",
            callback: (props) => {
              handleSelect(props.chartWrapper);
            }
          },
        ]}
      />
    </div>
  );
};

export default PieChart;
